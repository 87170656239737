import React from 'react'
import { Link } from 'gatsby'
import Meta from 'components/common/Meta'
import Button from '@material-ui/core/Button'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'

// markup
const NotFoundPage = () => {
  return (
    <main className="flex w-full h-screen items-center justify-center">
      <div className="p-6">
        <Meta title="404 not found" />
        <img src="/phase404.svg" alt="" width={400} title="404" />
        <h1 className="py-6">Page not found</h1>
        <p>
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for
          <br />
          <br />
          {/* <Link to='/'>Go home</Link> */}
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<HomeOutlinedIcon />}
            component={Link}
            to="/"
          >
            Take me home
          </Button>
        </p>
      </div>
    </main>
  )
}

export default NotFoundPage
